var o = Object.defineProperty;
var d = (i, e, t) => e in i ? o(i, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : i[e] = t;
var r = (i, e, t) => (d(i, typeof e != "symbol" ? e + "" : e, t), t);
import { ReactModel as l } from "@croquet/react";
class g extends l {
  constructor() {
    super(...arguments);
    r(this, "state");
    r(this, "stateTogether");
  }
  init(t) {
    super.init({ ...t, trackViews: !0 }), this.state = /* @__PURE__ */ new Map(), this.stateTogether = /* @__PURE__ */ new Map(), this.subscribe(this.id, "setState", this.setState), this.subscribe(this.id, "setStateTogether", this.setStateTogether);
  }
  setState({ id: t, newValue: s }) {
    s === void 0 ? this.state.delete(t) : this.state.set(t, s), this.publish(t, "updated", {});
  }
  setStateTogether({ id: t, viewId: s, newValue: a }) {
    let h = this.stateTogether.get(t);
    h === void 0 && (h = /* @__PURE__ */ new Map()), a === void 0 ? h.delete(s) : h.set(s, a), this.stateTogether.set(t, h), this.publish(t, "updated", {});
  }
  handleViewExit(t) {
    this.stateTogether.forEach((s, a) => {
      s.delete(t), this.publish(a, "updated", {});
    });
  }
}
g.register("ReactTogetherModel");
export {
  g as default
};
