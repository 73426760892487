import { jsxs as l, Fragment as m, jsx as p } from "react/jsx-runtime";
import a from "color-hash";
import "@croquet/react";
import "unique-names-generator";
import c from "../hooks/useHoveringViews.js";
import "react";
import "./ReactTogether.js";
import "../object_hash-1efe2599.js";
const f = new a();
function V({
  rtKey: r,
  children: e,
  className: t,
  options: n
}) {
  const [s, o] = c(r, n);
  let i = {};
  return o.length > 0 && (i = {
    outline: `2px solid ${f.hex(o[0])}`,
    animation: "clippath 3s linear infinite",
    borderRadius: "10px"
  }), /* @__PURE__ */ l(m, { children: [
    !1,
    /* @__PURE__ */ p("div", { ref: s, style: i, className: t, children: e })
  ] });
}
export {
  V as default
};
